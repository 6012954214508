import { ACTION_TYPES } from './action';

export const initialState = {
  btcUsd: 0,
  
  isShowFooter: true,
};

const ACTION_HANDLERS = {
  // 设置是否显示footer
  [ACTION_TYPES.COMMON_SET_SHOW_FOOTER]: (state, action) => {
    const newState = { ...state };
    newState.isShowFooter = action.data.isShowFooter;
    return newState;
  },
};

// ------------------------------------
// Reducer
// ------------------------------------
const reducer = (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

export default reducer;
