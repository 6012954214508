
import common from './common';

// ----------------------------------
// SERVER
// ----------------------------------
// const SERVER_DEVELOPMENT = 'http://47.108.195.149:30956/api';
const SERVER_DEVELOPMENT = `https://woxbtc.pro/api`;
const SERVER_PRODUCTION = `https://woxbtc.pro/api`;

export const SERVER = `${__DEV__ ? SERVER_DEVELOPMENT : SERVER_PRODUCTION}`;

export default {
  ...common(SERVER),
};
