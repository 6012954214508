// -----------------------------
// 常量配置
// -----------------------------
import keyMirror from 'keymirror';

// eslint-disable-next-line
export const LOCAL_STORAGE = keyMirror({
  CONFIG: null,
});

