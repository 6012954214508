import keyMirror from 'keymirror';
import { CALL_API } from '../../middleware/fetch';
import API from '../../middleware/apis';

export const ACTION_TYPES = keyMirror({
  COMMON_CONFIG: null,
  COMMON_SET_SHOW_FOOTER: null,
});

// 系统配置
export const requestConfig = success => {
  return {
    [CALL_API]: {
      type: ACTION_TYPES.COMMON_CONFIG,
      url: API.COMMON_CONFIG,
      method: 'GET',
      success,
    }
  };
}

/**
 * 是否显示footer
 */
export const setShowFooter = isShowFooter => {
  return {
    type: ACTION_TYPES.COMMON_SET_SHOW_FOOTER,
    data: { isShowFooter }
  };
};
