import { PropTypes } from 'prop-types';
import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';

import AppLayout from '../layout';
import AsyncComponent from '../routes';
import LoadingPlaceholder from '../components/LoadingPlaceholder';

const AppContainer = ({ store, history }) => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <ConnectedRouter history={history}>
          <AppLayout>
            <Suspense fallback={<LoadingPlaceholder />}>
              <Switch location={location}>
                <Route
                  exact
                  path='/'
                  render={props => <AsyncComponent {...props} componentName='Index1' />}
                />

                <Route
                  exact
                  path='/member'
                  render={props => <AsyncComponent {...props} componentName='MemberDiscount' />}
                />
                <Route
                  exact
                  path='/landing2c'
                  render={props => <AsyncComponent {...props} componentName='Landing2C' />}
                />
                <Route
                  exact
                  path='/user/trial'
                  render={props => <AsyncComponent {...props} componentName='UserTrial' />}
                />
                <Route
                  exact
                  path='/ib'
                  render={props => <AsyncComponent {...props} componentName='IB' />}
                />
                <Route
                  exact
                  path='/ib/apply'
                  render={props => <AsyncComponent {...props} componentName='IBApply' />}
                />
                <Route
                  exact
                  path='/arbitrage'
                  render={props => <AsyncComponent {...props} componentName='Arbitrage' />}
                />
                <Route
                  exact
                  path='/recharge/cashgrants'
                  render={props => <AsyncComponent {...props} componentName='ActivityRechargeCashGrants' />}
                />
                <Route
                  exact
                  path='/profit'
                  render={props => <AsyncComponent {...props} componentName='ActivityProfit' />}
                />

                <Route
                  render={props => <AsyncComponent {...props} componentName='PageNotFound' />}
                />
              </Switch>
            </Suspense>
          </AppLayout>
        </ConnectedRouter>
      </BrowserRouter>
    </Provider>
  );
};

AppContainer.propTypes = {
  store: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default AppContainer;
