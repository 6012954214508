import React, {lazy} from 'react';
import PropTypes from 'prop-types';

// --------------  首页  --------------
const Index1 = lazy(() => import(/* webpackChunkName: "Index" */ './Index1'));
// 会员打折banner跳转介绍页
const MemberDiscount = lazy(() => import(/* webpackChunkName: "MemberDiscount" */ './MemberDiscount'));

// Landing2C
const Landing2C = lazy(() => import(/* webpackChunkName: "Landing2C" */ './Landing2C'));
// 用户体验金
const UserTrial = lazy(() => import(/* webpackChunkName: "UserTrial" */ './User/Trial'));
// IB
const IB = lazy(() => import(/* webpackChunkName: "IB" */ './IB/Index'));
// IB
const IBApply = lazy(() => import(/* webpackChunkName: "IbApply" */ './IB/Apply'));

// 搬砖介绍页
const Arbitrage = lazy(() => import(/* webpackChunkName: "Arbitrage" */ './Arbitrage'));

// 活动页
const ActivityRechargeCashGrants = lazy(() => import(/* webpackChunkName: "ActivityRechargeCashGrants" */ './Activity/RechargeCashGrants'));
const ActivityProfit = lazy(() => import(/* webpackChunkName: "ActivityProfit" */ './Activity/Profit'));

// 404
const PageNotFound = lazy(() => import(/* webpackChunkName: "PageNotFound" */ './PageNotFound'));

const Conponents = {
  // 首页
  Index1,
  // 会员打折banner跳转介绍页
  MemberDiscount,
  Landing2C,
  UserTrial,
  // IB 代理界面
  IB,
  IBApply,
  // 搬砖介绍页
  Arbitrage,
  
  // 活动页 - 下级首充3%返佣
  ActivityRechargeCashGrants,
  // 活动页 - 收益增加
  ActivityProfit,

  PageNotFound,
};

const AsyncComponent = props => {
  const {componentName} = props;

  const Component = Conponents[componentName];

  return <Component {...props} />;
};

AsyncComponent.propTypes = {
  componentName: PropTypes.string.isRequired
};

AsyncComponent.defaultProps = {};

export default AsyncComponent;
