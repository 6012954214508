/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import browserAttr from '../utils/browserAttr';

// components

// utils
import { preventChina } from '../utils/chinaPreventer';

// asset

// languages
import './style.scss';

const className = 'pageLayout';

class PageLayout extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired
  };

  shouldComponentUpdate() {
    return true;
  }

  componentDidMount() {
    preventChina();
  }

  // private

  // render
  renderMobile = () => {
    return (
      <div className={`${className}`}>
        {this.props.children}
      </div>
    );
  }

  renderWeb = () => {
    return (
      <div className={`${className}`}>
        <div className={`${className}-web-box`}>
          {this.props.children}
        </div>
      </div>
    )
  }

  render() {
    if (browserAttr.versions.mobile) {
      return this.renderMobile();
    }

    return this.renderWeb();
  }
}

const mapStateToProps = state => ({
  common: state.common,
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(PageLayout);
