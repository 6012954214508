/**
 * 公共接口
 */
export default SERVER => ({

  /**
   * 全局配置信息
   * method GET
   */
  COMMON_CONFIG: `${SERVER}/config`,

});