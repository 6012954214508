// 中国禁止

export function preventChina() {
  const code = (navigator.browserLanguage || navigator.language).toLowerCase().split('-')[0];

  const time = new Date();
  const offset = time.getTimezoneOffset() / 60; // 北京时间差为 -8

  // 时区 -10 ~ -6 之间 且为中文，则跳转到谷歌
  if (offset < -6 && offset > -10 && code == 'zh') {
    window.location.href = 'https://www.google.com';
  }
}
